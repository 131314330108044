import { getCookie } from 'cookies-next';

import { sendSentryError } from '@/lib/sentry/sentry';

export const facebookPixelId = '337561038694031';

declare global {
  interface Window {
    fbq?: (...args: any) => void;
    ttq?: {
      track: (eventName: string, properties: any) => void;
    };
    axon?: (type: string, eventName: string, properties: any) => void;
  }
}

/**
 * Sends a pixel event to Facebook with the specified event name and properties.
 *
 * @param {string} eventName - The name of the event to send to Facebook Pixel.
 * @param {object} [properties={}] - Additional properties to send with the event.
 * The properties object can include any custom data you want to send with the event.
 * The fbp and fbc values will automatically be added if available.
 */
export const sendFacebookPixelEvent = (
  eventName: string,
  properties: any = {},
  eventId?: string
) => {
  if (typeof window !== 'undefined' && window.fbq) {
    const fbp = getCookie('_fbp');
    const fbc = getCookie('_fbc');
    window.fbq(
      'trackCustom',
      eventName,
      {
        ...properties,
        ...(fbp && { fbp }),
        ...(fbc && { fbc }),
      },
      {
        eventID: eventId,
      }
    );
  }
};

/**
 * Sends a pixel event to TikTok with the specified event name and properties.
 *
 * @param {string} eventName - The name of the event to send to TikTok Pixel.
 * @param {object} [properties={}] - Additional properties to send with the event.
 * The properties object can include any custom data you want to send with the event.
 */
export const sendTikTokPixelEvent = (eventName: string, properties: any = {}) => {
  if (window !== undefined && window.ttq) {
    window.ttq.track(eventName, properties);
  }
};

export const getGAId = () => {
  const measurementId = process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID;
  const trimmedId = measurementId?.split('-')[1];
  const gaFullSessionId = getCookie(`_ga_${trimmedId}`) as string;
  const gaClientId = gaFullSessionId?.split('.')[2] as string;
  return gaClientId;
};

export const sendAxonEvent = (eventName: string, properties: any = {}) => {
  if (typeof window !== 'undefined' && window.axon) {
    try {
      window.axon('track', eventName, properties);
    } catch (error) {
      sendSentryError('sendAxonEvent:', error);
    }
  }
};
