import { getCookie } from 'cookies-next';

import { sendSentryMessage } from '@/lib/sentry/sentry';
import { isProduction } from '@/lib/vercel-env';

let afIterator = 0;

export const appsFlyerSetCustomerUserId = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (isProduction()) {
    if (window.AF) {
      const afUserId = getCookie('afUserId');
      if (afUserId && typeof afUserId === 'string') {
        window.AF('pba', 'setCustomerUserId', afUserId);
      }
    } else {
      if (afIterator > 5) {
        sendSentryMessage('WINDOW_AF_NOT_AVAILABLE_AFTER_5_TRIES');
      } else {
        afIterator++;
        setTimeout(() => {
          appsFlyerSetCustomerUserId();
        }, 1000);
      }
    }
  }
};

export const appsFlyerEvent = (eventName: string, eventValue: unknown) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (isProduction()) {
    if (window.AF) {
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventValue,
        eventName,
      });
    } else {
      sendSentryMessage('WINDOW_AF_NOT_AVAILABLE_FOR_EVENT');
    }
  }
};
