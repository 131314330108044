//Sentry is initialized through the wizard
import type { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import { isProdOrPreview } from '@/lib/vercel-env';

export const sendSentryError = (name: string, error: any, extraInfo?: Record<string, any>) => {
  if (!isProdOrPreview()) {
    console.warn(`SEND_SENTRY_ERROR: ${name}`, extraInfo);
    console.error(error);
    return;
  }
  Sentry.captureException(error, (scope) => {
    scope.setTransactionName(name);
    if (extraInfo) {
      scope.setExtras(extraInfo);
    }
    return scope;
  });
};

export const sendSentryErrorTag = ({
  error,
  category = 'support',
  action,
  support = 'register_dna_kit',
}: {
  error: ApolloError;
  category?: string;
  support?: string;
  action?: string;
}) => {
  if (!isProdOrPreview()) {
    return;
  }
  Sentry.captureException(error, {
    tags: {
      category,
      support,
      action,
    },
  });
};

export const sendSentryMessage = (name: string) => {
  Sentry.captureMessage(name);
};
