export enum VercelEnvironment {
  production = 'production',
  preview = 'preview',
  development = 'development',
  local = 'local',
}

type VercelEnv = 'production' | 'preview' | 'development';

export const vercelEnvironment: VercelEnvironment =
  VercelEnvironment[
    (process.env.NEXT_PUBLIC_VERCEL_ENV as VercelEnv) ||
      (process.env.VERCEL_ENV as VercelEnv) ||
      'local'
  ];

console.debug('## vercelEnvironment:', vercelEnvironment);

export const isProdOrPreview = (): boolean => {
  return [VercelEnvironment.production, VercelEnvironment.preview].includes(vercelEnvironment);
};

export const isProduction = (): boolean => {
  return vercelEnvironment === VercelEnvironment.production;
};
