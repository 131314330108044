import '@/styles/globals/styles.scss';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import { clsx } from 'clsx';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import localFont from 'next/font/local';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Close from 'public/icons/close-white.svg';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';

import { Insights } from '@/components/Insights';
import { ScreenSizeProvider } from '@/context/ScreenSizeContext';
import { SegmentProvider, useSegment } from '@/context/SegmentContext';
import { ShopContextProvider } from '@/context/ShopContext';
import { UIContextProvider } from '@/context/UIContext';
import { UserContextProvider } from '@/context/UserContext';
import { YotpoContextProvider } from '@/context/YotpoContext';
import useCachedQueryParam from '@/hooks/useCachedQueryParam';
import { useApollo } from '@/lib/apollo/apollo-client';
import { appsFlyerSetCustomerUserId } from '@/lib/apps-flyer/apps-flyer';
import {
  everflowPageViewAnalytics,
  pageViewAnalytics,
  qrCodeAnalytics,
  storeCampaignData,
} from '@/utils/analytics';
import { capitalize } from '@/utils/misc';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const fontModernGothic = localFont({
  src: [
    {
      path: '../../public/asset/ModernGothic/ModernGothic-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/asset/ModernGothic/ModernGothic-Light.otf',
      weight: '300',
      style: 'normal',
    },
  ],
});

const fontSabonLTStd = localFont({
  src: [
    {
      path: '../../public/asset/SabonLTStd/SabonLTStd-Roman.otf',
      weight: '400',
      style: 'normal',
    },
  ],
});

const inter = Inter({ subsets: ['latin'] });

const fontModernGothicTrial = localFont({
  src: [
    {
      path: '../../public/asset/ModernGothicTrial/ModernGothic-Light-trial.otf',
      weight: '300',
      style: 'normal',
    },
  ],
});

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);
  const apolloClient = useApollo(pageProps);
  const { utm_source, utm_medium, utm_campaign } = router.query;
  useCachedQueryParam('promocode');
  const logRocketIntializationAttempted = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && router.pathname !== '/') {
      if (logRocketIntializationAttempted.current) {
        return;
      }
      logRocketIntializationAttempted.current = true;
      // INFO: 4/10/2025 Turning off LogRocket for Sentry
      // initializeLogRocket();
      appsFlyerSetCustomerUserId();
      storeCampaignData();
    }
  }, [router.pathname]);

  useEffect(() => {
    // on app load, scroll to the position saved in sessionStorage
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    }

    // save scroll position when navigating away from page
    const saveScrollPosition = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };

    window.addEventListener('beforeunload', saveScrollPosition);

    return () => {
      window.removeEventListener('beforeunload', saveScrollPosition);
    };
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    //page analytics
    if (typeof window !== 'undefined') {
      pageViewAnalytics(router?.asPath, generateWebPageTitle(router.asPath));
    }
  }, [router.isReady, router.asPath]);

  useEffect(() => {
    router.events.on('routeChangeComplete', everflowPageViewAnalytics);

    return () => {
      router.events.off('routeChangeComplete', everflowPageViewAnalytics);
    };
  }, [router.events]);

  useEffect(() => {
    if (!router.isReady) return;
    //qr analytics
    if (typeof window !== 'undefined') {
      if (utm_source && utm_medium && utm_campaign) {
        qrCodeAnalytics(utm_source.toString(), utm_medium.toString(), utm_campaign.toString());
        if (
          utm_source === 'S2 Delivery Assets' &&
          utm_medium === 'QR' &&
          utm_campaign === 'Strap Insert Card'
        ) {
          window.location.href = 'https://alter.onelink.me/CGdK/hspy21dh';
        } else if (utm_campaign === 'Screen Setup Instructions') {
          return;
        }
      }
    }
  }, [router.isReady, router.query, utm_campaign, utm_medium, utm_source]);

  const generateWebPageTitle = (pathName: string) => {
    const pathNoSearch = pathName.split('?')[0];
    const parts = pathNoSearch.split('/');
    const editedParts = parts.map((part, index) => {
      if (index !== parts.length - 1) {
        return capitalize(part) + ' - ';
      }
      return capitalize(part);
    });

    if (pathName !== '/') {
      editedParts[0] = 'Alter | ';
      return editedParts.join('');
    }
    return 'Alter';
  };

  return (
    <>
      <style jsx global>
        {`
          :root {
            --modern-gothic: ${fontModernGothic?.style?.fontFamily};
            --inter: ${inter?.style.fontFamily};
            --modern-gothic-trial: ${fontModernGothicTrial?.style.fontFamily};
            --sabon-lt-std: ${fontSabonLTStd?.style.fontFamily};
          }
        `}
      </style>
      <ScreenSizeProvider>
        <SegmentProvider>
          <ApolloProvider client={apolloClient}>
            <UserContextProvider>
              <ShopContextWrapper pageProps={pageProps}>
                <YotpoContextProvider yotpoResponse={pageProps.yotpoResponse}>
                  <UIContextProvider>
                    <>
                      <Head>
                        <title>{generateWebPageTitle(router.asPath)}</title>
                        <meta name="theme-color" content="#FFFCFF" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta
                          property="og:title"
                          content="Personalized fitness system using DNA and biometric data"
                        />
                        <meta property="og:image" content="/Alter_Product_Mirror_Workout.webp" />
                        <link
                          rel="apple-touch-icon"
                          sizes="180x180"
                          href="/apple-touch-icon.png?v=2"
                        />
                        <link
                          rel="icon"
                          type="image/png"
                          sizes="32x32"
                          href="/favicon-32x32.png?v=2"
                        />
                        <link
                          rel="icon"
                          type="image/png"
                          sizes="16x16"
                          href="/favicon-16x16.png?v=2"
                        />
                        <link rel="manifest" href="/site.webmanifest?v=2" />
                        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#000000" />
                        <link rel="shortcut icon" href="/favicon.ico?v=2" />
                        <meta name="msapplication-TileColor" content="#000000" />
                        {/* <script
                    type="text/javascript"
                    src="/js/createAuthToken.js"
                    defer
                  /> */}
                        {/* <script
                      src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js"
                      defer
                    ></script> */}
                      </Head>
                      <Script
                        src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js"
                        strategy="afterInteractive" // Or use "lazyOnload" if you prefer to load it after the page is loaded
                        defer
                      />

                      {getLayout(<Component {...pageProps} />)}
                    </>
                  </UIContextProvider>
                </YotpoContextProvider>
              </ShopContextWrapper>
            </UserContextProvider>
          </ApolloProvider>
        </SegmentProvider>
      </ScreenSizeProvider>
      <ToastContainer
        position="top-right"
        // autoClose={2500}
        hideProgressBar
        className="w-full mt-12 md:mt-38 md:mr-74 p-0"
        icon={(props) => <></>}
        toastClassName={(props) =>
          clsx(
            'bg-alter-black-80 text-alter-bone break-keep w-96',
            props?.type === 'error' && 'bg-alter-red',
            props?.type === 'warning' && 'bg-alter-warning text-alter-black',
            'rounded-lg text-base flex flex-row items-center justify-start gap-5 pr-4'
          )
        }
        closeButton={(props) => <Image width={16} height={16} src={Close} alt="logo" />}
        bodyClassName="p-0"
      />
      <Insights />
    </>
  );
}

const ShopContextWrapper: React.FC<{ children: React.ReactNode; pageProps: any }> = ({
  children,
  pageProps,
}) => {
  const { segmentAnonymousId } = useSegment();

  return (
    <ShopContextProvider
      segmentAnonymousId={segmentAnonymousId}
      availableDiscountCodes={pageProps.availableDiscountCodes}
    >
      {children}
    </ShopContextProvider>
  );
};

export default App;
